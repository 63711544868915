var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form',{attrs:{"layout":"horizontal","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"form":_vm.formObj},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"mylayout-left-right responsive"},[_c('div',{staticClass:"mylayout-left"},[_c('a-card',{staticClass:"text-center",attrs:{"bordered":false}},[_c('ImageUploader',{attrs:{"current-image-url":_vm.currentModelImageUrl,"border-type":"round","size":"large","max-width":1024,"max-height":1024},on:{"error":_vm.handleFileUploadError,"change":_vm.hadleFileUploadChange}},[_c('DroneIcon',{staticClass:"anticon"})],1),_vm._v(" "),_c('div',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t('drone_model.field.image')))])],1)],1),_vm._v(" "),_c('div',{staticClass:"mylayout-right"},[_c('a-card',{attrs:{"bordered":false}},[_c('div',{staticClass:"myform-section-title"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('drone_model.field.section.general'))+"\n\t\t\t\t")]),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('drone_model.field.name'),"extra":_vm.$t('drone_model.field.name.help')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
							'name' ,
							{ rules: [
								{ required: true ,whitespace: true ,message: _vm.$tt('validate.required','drone_model.field.name') },
							] },
						]),expression:"[\n\t\t\t\t\t\t\t'name' ,\n\t\t\t\t\t\t\t{ rules: [\n\t\t\t\t\t\t\t\t{ required: true ,whitespace: true ,message: $tt('validate.required','drone_model.field.name') },\n\t\t\t\t\t\t\t] },\n\t\t\t\t\t\t]"}],attrs:{"type":"text","placeholder":_vm.$t('drone_model.field.name.placeholder')}})],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('drone_model.field.display_name'),"extra":_vm.$t('drone_model.field.display_name.help')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
							'display_name' ,
							{ rules: [
								{ required: true ,whitespace: true ,message: _vm.$tt('validate.required','drone_model.field.display_name') },
							] },
						]),expression:"[\n\t\t\t\t\t\t\t'display_name' ,\n\t\t\t\t\t\t\t{ rules: [\n\t\t\t\t\t\t\t\t{ required: true ,whitespace: true ,message: $tt('validate.required','drone_model.field.display_name') },\n\t\t\t\t\t\t\t] },\n\t\t\t\t\t\t]"}],attrs:{"type":"text","placeholder":_vm.$t('drone_model.field.display_name.placeholder')}})],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('drone_model.field.drone_name_prefix'),"extra":_vm.$t('drone_model.field.drone_name_prefix.help')}},[_c('a-input',{directives:[{name:"show",rawName:"v-show",value:(_vm.canUpdateImportant),expression:"canUpdateImportant"},{name:"decorator",rawName:"v-decorator",value:([
							'drone_name_prefix' ,
							{ rules: [
								{ required: true ,whitespace: true ,message: _vm.$tt('validate.required','drone_model.field.drone_name_prefix') },
							] },
						]),expression:"[\n\t\t\t\t\t\t\t'drone_name_prefix' ,\n\t\t\t\t\t\t\t{ rules: [\n\t\t\t\t\t\t\t\t{ required: true ,whitespace: true ,message: $tt('validate.required','drone_model.field.drone_name_prefix') },\n\t\t\t\t\t\t\t] },\n\t\t\t\t\t\t]"}],staticStyle:{"width":"200px"},attrs:{"max-length":15,"type":"text","placeholder":_vm.$t('drone_model.field.display_name.placeholder')}},[_c('span',{attrs:{"slot":"addonAfter"},slot:"addonAfter"},[_vm._v("0XX")])]),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.canUpdateImportant),expression:"!canUpdateImportant"}],staticClass:"ant-form-text"},[_c('span',{staticClass:"text-secondary text-bold"},[_vm._v(_vm._s(_vm.currentNamePrefix))]),_vm._v("0XX\n\t\t\t\t\t")])],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('drone_model.field.drone_type'),"extra":_vm.$t('drone_model.field.drone_type.help')}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
							'drone_type' ,
							{
								rules: [
								{ required: true ,message: _vm.$tt('validate.required','drone_model.field.drone_type') },
								] ,
								'initialValue': 'spraying',
							},
						]),expression:"[\n\t\t\t\t\t\t\t'drone_type' ,\n\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t{ required: true ,message: $tt('validate.required','drone_model.field.drone_type') },\n\t\t\t\t\t\t\t\t] ,\n\t\t\t\t\t\t\t\t'initialValue': 'spraying',\n\t\t\t\t\t\t\t},\n\t\t\t\t\t\t]"}]},[_c('a-radio',{attrs:{"value":"spraying"}},[_vm._v(_vm._s(_vm.$tenum('drone_type','spraying')))]),_vm._v(" "),_c('a-radio',{attrs:{"value":"mapping"}},[_vm._v(_vm._s(_vm.$tenum('drone_type','mapping')))])],1)],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('drone_model.field.inspection_type'),"extra":_vm.$t('drone_model.field.inspection_type.help')}},[_c('a-select',{directives:[{name:"show",rawName:"v-show",value:(_vm.canUpdateImportant),expression:"canUpdateImportant"},{name:"decorator",rawName:"v-decorator",value:([
							'inspection_type' ,
							{
								rules: [
									{ required: true,message: _vm.$tt('validate.required','drone_model.field.inspection_type') },
								],
							},
						]),expression:"[\n\t\t\t\t\t\t\t'inspection_type' ,\n\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t{ required: true,message: $tt('validate.required','drone_model.field.inspection_type') },\n\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t},\n\t\t\t\t\t\t]"}],staticStyle:{"width":"200px"},attrs:{"get-popup-container":_vm.getPopupContainer,"placeholder":_vm.$t('drone_model.field.inspection_type.placeholder')}},[_c('a-select-option',{attrs:{"value":"tgd1x"}},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(_vm.$tenum('inspection_type','tgd1x'))+"\n\t\t\t\t\t\t")])],1),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.canUpdateImportant),expression:"!canUpdateImportant"}],staticClass:"ant-form-text"},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.$tenum('inspection_type',_vm.currentInspectionType))+"\n\t\t\t\t\t")])],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('drone_model.field.hgr_ref_code'),"extra":_vm.$t('drone_model.field.hgr_ref_code.help')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['hgr_ref_code']),expression:"['hgr_ref_code']"}],staticStyle:{"width":"250px"},attrs:{"placeholder":_vm.$t('drone_model.field.hgr_ref_code.placeholder')}})],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('drone_model.field.firmware_set'),"extra":_vm.$t('drone_model.field.firmware_set.help')}},[(_vm.canUpdateFirmwareSet)?_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
							'firmware_set_id' ,
							{
								rules: [
									{ required: true,message: _vm.$tt('validate.required','drone_model.field.firmware_set') },
								],
							},
						]),expression:"[\n\t\t\t\t\t\t\t'firmware_set_id' ,\n\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t{ required: true,message: $tt('validate.required','drone_model.field.firmware_set') },\n\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t},\n\t\t\t\t\t\t]"}],staticStyle:{"width":"200px"},attrs:{"get-popup-container":_vm.getPopupContainer,"placeholder":_vm.$t('drone_model.field.firmware_set.placeholder')}},_vm._l((_vm.firmwareSets),function(firmwareSet){return _c('a-select-option',{key:firmwareSet.id,attrs:{"value":firmwareSet.id}},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(firmwareSet.name)+"\n\t\t\t\t\t\t")])}),1):_c('span',{staticClass:"ant-form-text"},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm._f("emptyCheck")(_vm.currentFirmwareSet))+"\n\t\t\t\t\t")])],1),_vm._v(" "),(_vm.mode === 'update')?_c('a-form-item',{attrs:{"label":_vm.$t('drone_model.field.status')}},[_c('span',{staticClass:"ant-form-text"},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.$tenum('drone_model.status',_vm.currentModelStatus))+"\n\t\t\t\t\t")])]):_vm._e(),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('drone_model.field.release_date'),"extra":_vm.$t('drone_model.field.release_date.help')}},[_c('MyDatePicker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['release_date',
								{
									rules: [
										{ required: true ,whitespace: true ,message: _vm.$tt('validate.required','drone_model.field.release_date') },
									] ,
								}]),expression:"['release_date',\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t{ required: true ,whitespace: true ,message: $tt('validate.required','drone_model.field.release_date') },\n\t\t\t\t\t\t\t\t\t] ,\n\t\t\t\t\t\t\t\t}]"}],attrs:{"format":"DD MMMM YYYY"}})],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('drone_model.field.warranty_duration'),"extra":_vm.$t('drone_model.field.warranty_duration.help')}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['warranty_duration',
								{
									rules: [
										{ required: true ,message: _vm.$tt('validate.required','drone_model.field.warranty_duration') },
									] ,
								}]),expression:"['warranty_duration',\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t{ required: true ,message: $tt('validate.required','drone_model.field.warranty_duration') },\n\t\t\t\t\t\t\t\t\t] ,\n\t\t\t\t\t\t\t\t}]"}],staticClass:"myinput-number",attrs:{"min":0,"step":1,"precision":0,"length":"30"}}),_vm._v(" "),_c('span',{staticClass:"ant-form-text"},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.$t('common.day'))+"\n\t\t\t\t\t")])],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('drone_model.field.warranty_flight_time'),"extra":_vm.$t('drone_model.field.warranty_flight_time.help')}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['warranty_flight_time',
								{
									rules: [
										{ required: true ,message: _vm.$tt('validate.required','drone_model.field.warranty_flight_time') },
									] ,
								}]),expression:"['warranty_flight_time',\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t{ required: true ,message: $tt('validate.required','drone_model.field.warranty_flight_time') },\n\t\t\t\t\t\t\t\t\t] ,\n\t\t\t\t\t\t\t\t}]"}],staticClass:"myinput-number",attrs:{"min":0,"step":1,"precision":0,"length":"30"}}),_vm._v(" "),_c('span',{staticClass:"ant-form-text"},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.$t('common.hour'))+"\n\t\t\t\t\t")])],1),_vm._v(" "),_c('hr'),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('drone_model.field.remark')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remark']),expression:"['remark']"}],attrs:{"auto-size":{ minRows: 3,maxRows: 8 },"placeholder":_vm.$t('drone_model.field.remark.placeholder')}})],1),_vm._v(" "),_c('a-form-item',_vm._b({},'a-form-item',_vm.wrapperOnlyLayout,false),[_vm._t("submitSlot",function(){return [_c('a-button',{staticClass:"btn-submit",attrs:{"loading":_vm.loading,"type":"info","html-type":"submit","size":"large"}},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(_vm.$t('common.save'))+"\n\t\t\t\t\t\t")])]},{"formLoading":_vm.loading})],2)],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }